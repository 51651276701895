{
  "name": "@holiday-jp/holiday_jp",
  "version": "2.4.0",
  "description": "Japanese holidays",
  "main": "lib/holiday_jp.js",
  "types": "lib/holiday_jp.d.ts",
  "files": ["lib"],
  "scripts": {
    "test": "mocha test/*.js",
    "generate": "git submodule update; cd holiday_jp/; git fetch origin master; git reset --hard origin/master; cd ../; node scripts/generate.js",
    "build": "npm run generate; webpack; node scripts/build.js;rm scripts/holiday_jp_webpacked.js;cd ./release/; uglifyjs holiday_jp.js -c -m --source-map -o holiday_jp.min.js"
  },
  "repository": {
    "type": "git",
    "url": "https://github.com/holiday-jp/holiday_jp-js"
  },
  "keywords": [
    "holidays"
  ],
  "author": "Ken'ichiro Oyama",
  "license": "MIT",
  "bugs": {
    "url": "https://github.com/holiday-jp/holiday_jp-js/issues"
  },
  "homepage": "https://github.com/holiday-jp/holiday_jp-js",
  "devDependencies": {
    "@playwright/test": "^1.22.2",
    "chai": "^4.3.6",
    "js-yaml": "^3.13.1",
    "mocha": "^8.2.1",
    "moment": "^2.23.0",
    "request": "^2.88.0",
    "uglify-js": "^3.4.9",
    "webpack": "^4.28.2",
    "webpack-cli": "^4.2.0"
  }
}
